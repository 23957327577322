import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Flex, Button, Box} from "rebass/styled-components";
import styled, {useTheme, css} from "styled-components";
import {CarItem} from "./components";
import { Swiper, SwiperSlide } from 'swiper/react';
import PropTypes from "prop-types";
import {useTranslation} from "next-i18next";
import {useRouter} from 'next/router';

const TradeCarsSection = (props) => {
  const { stockCars, orderCars, shippingCars, screenWidth: width } = props;

  const [currentCars, setCurrentCars] = useState([]);
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(1);

  const swiperRef = useRef(null);
  const theme = useTheme();
  const {push} = useRouter();
  const { t } = useTranslation('common');
  const smMobileWidth = theme.breakpointsNumeric[0];

  const switchCategory = useCallback((index) => {
    if (index === 0) {
      setCurrentCars(stockCars);
    } else if (index === 1) {
      setCurrentCars(orderCars);
    } else if (index === 2) {
      setCurrentCars(shippingCars);
    } else {
      setCurrentCars([])
    }
    setCurrentCategoryIndex(index);
  }, [orderCars, shippingCars, stockCars]);

  const openCatalogue = useCallback(() => {
    push('/catalogue');
  }, [push]);

  useEffect(() => {
    if (!currentCars.length) {
      setCurrentCars(orderCars);
      setCurrentCategoryIndex(1)
    }
  }, [setCurrentCategoryIndex, setCurrentCars, orderCars]);

  return (
    <>
      <TypeSelector>
        <Flex
          sx={{
            border: '1px solid #000',
            borderRadius: 12
          }}
        >
          <SelectButton
            isSmMobile={width <= smMobileWidth}
            active={currentCategoryIndex === 0}
            variant='borderless'
            color='text.secondary'
            disabled={!stockCars.length}
            onClick={() => {
              switchCategory(0)
            }}
            sx={{
              borderRadius: '12px 0 0 12px',
              '&:hover': {
                background: '#000',
                color: '#fff'
              }
            }}
          >
            {t('mainPageTradeCarsSectionSelectorStock')}
          </SelectButton>
          <SelectButton
            isSmMobile={width <= smMobileWidth}
            active={currentCategoryIndex === 1}
            variant='outlined'
            color='text.secondary'
            disabled={!orderCars.length}
            onClick={() => {
              switchCategory(1)
            }}
            sx={{
              borderTop: currentCategoryIndex === 1 ? '1px solid #000' : 'none',
              borderBottom: currentCategoryIndex === 1 ? '1px solid #000' : 'none',
              borderRight: '1px solid #000',
              borderLeft: '1px solid #000',
              alignSelf: 'center',
              '&:hover': {
                borderTop: '1px solid #000',
                borderBottom: '1px solid #000',
              },
            }}
          >
            {t('mainPageTradeCarsSectionSelectorOrder')}
          </SelectButton>
          <SelectButton
            isSmMobile={width <= smMobileWidth}
            active={currentCategoryIndex === 2}
            variant='borderless'
            color='text.secondary'
            disabled={!shippingCars.length}
            onClick={() => {
              switchCategory(2)
            }}
            sx={{
              borderRadius: '0 12px 12px 0',
              '&:hover': {
                background: '#000',
                color: '#fff'
              }
            }}
          >
            {t('mainPageTradeCarsSectionSelectorShipping')}
          </SelectButton>
        </Flex>
      </TypeSelector>
      <CarsWrapper>
        {currentCars.length > 0 && (
          <>
            <NavIcon
              id="previousButton"
              onClick={() => swiperRef.current.swiper.slidePrev()}
              sx={{
                left: '-15px',
                '@media (max-width: 1600px)': {
                  left: '70px'
                },
                '@media (max-width: 1200px)': {
                  left: '-15px'
                }
              }}
            >
              <Arrow />
            </NavIcon>
            <NavIcon
              id="nextButton"
              onClick={() => swiperRef.current.swiper.slideNext()}
              sx={{
                right: '-15px',
                '@media (max-width: 1600px)': {
                  right: '60px'
                },
                '@media (max-width: 1200px)': {
                  right: '-15px'
                }
              }}
            >
              <Arrow right />
            </NavIcon>
            <Swiper
              breakpoints={{
                1600: {
                  slidesPerView: 4
                },
                1400: {
                  slidesPerView: 3
                },
                800: {
                  slidesPerView: 2,
                },
                400: {
                  slidesPerView: 1
                }
              }}
              ref={swiperRef}
            >
              {currentCars.map(car => (
                <SwiperSlide key={`car-${car.id}`} style={{
                  padding: '20px',
                }}>
                  <CarItem screenWidth={width} car={car}/>
                </SwiperSlide>
              ))}
            </Swiper>
          </>
        )}
      </CarsWrapper>
      <AllCarsButton
        variant='outlined'
        color='text.black'
        alignSelf='center'
        onClick={openCatalogue}
      >
        {t('mainPageTradeCarsSectionSelectorAllCars')}
      </AllCarsButton>
    </>
  )
};

const SelectButton = styled(Button)`
  text-transform: uppercase;
  font-size: 12px;

  ${({ active }) => active && css`
    background: #000;
    color: #fff;
    svg {
      fill: #fff !important;
    }
  `}
  
  ${({ isSmMobile }) => isSmMobile && css`
    min-width: 50px !important;
    white-space: break-spaces;
  `}
`

export const Arrow = styled(Box)`
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 5px;
  text-align: center;
  position: relative;
  ${({ right }) => right? `
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    right: 3px;
  ` : `
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    left: 2px;
  `};
`

export const NavIcon = styled(Flex)`
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  position: absolute;
  border-radius: 50%;
  background: #fff;
  border: 2px solid #000;
  top: calc(50% - 35px);
  z-index: 999;
  &:hover {
    ${Arrow} {
      border: solid #fff;
      border-width: 0 3px 3px 0;
    };
    background: #000;
    cursor: pointer;
  }
`

const AllCarsButton = styled(Button)`
  width: 160px;
  margin-top: 35px;
  border-radius: 12px;
  font-size: 12px;
  text-transform: uppercase;
`

const TypeSelector = styled(Flex)`
  width: 100%;
  justify-content: center;
  margin: 10px 0 60px;
  @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    margin: 20px 0 20px;
  }
`

const CarsWrapper = styled(Flex)`
  justify-content: center;
  width: 100%;
  align-items: center;
  flex-wrap: nowrap;
  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    flex-wrap: wrap;
  }
  .swiper-wrapper {
    //min-width: 750px;
  }
  .swiper-slide {
    min-width: 295px;
  }
`

TradeCarsSection.propTypes = {
  screenWidth: PropTypes.number.isRequired,
  stockCars: PropTypes.array,
  orderCars: PropTypes.array,
  shippingCars: PropTypes.array,
}

TradeCarsSection.defaultProps = {
  stockCars: [],
  orderCars: [],
  shippingCars: [],
}

export default TradeCarsSection;
