import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {Flex, Box, Text} from "rebass/styled-components";
import styled, {useTheme, css} from "styled-components";
import {fuels} from "../../../../../constants/carAttributesMap";
import {apiPublicBaseUrl} from "../../../../../constants/api";
import Link from 'next/link';
import toTitleCase from "../../../../../util/toTitlecase";
import {i18n} from "next-i18next";
import Image from 'next/image'

const CarItem = ({ car, screenWidth: width, sx }) => {
  const theme = useTheme();
  const smMobileWidth = theme.breakpointsNumeric[0];
  const {resolvedLanguage} = i18n || {};

  const fallbackUrl = 'https://media.istockphoto.com/vectors/thumbnail-image-vector-graphic-vector-id1147544807?k=20&m=1147544807&s=612x612&w=0&h=pBhz1dkwsCMq37Udtp9sfxbjaMl27JUapoyYpQm0anc=';

  const [imageUrl, setImageUrl] = useState(car.thumb);
  
  const onImageError = useCallback(() => {
    setImageUrl(fallbackUrl)
  }, []);

  return (
    // <Link href={`/catalogue/cars/${car.uuid}`}>
      <CarWrapper
        as='a'
        href={`/catalogue/cars/${car.uuid}`}
        isSmMobile={width <= smMobileWidth}
        sx={{
          pb: 2,
          my: [2, 2, 1, 0],
          mx: ['auto', 'auto', 3, 3],
          width: '100%',
          ...sx
        }}
      >
        <Flex
          sx={{
            height: '40px',
            alignItems: 'center'
          }}
        >
          <CarTitle fontFamily="primary" color='text.secondary'>{toTitleCase(car.brand)} {toTitleCase(car.model)} {toTitleCase(car.trim || '')}</CarTitle>
        </Flex>
        <CarThumb>
          {car.images?.length > 0 && (
            <Box
              sx={{
                position: 'relative',
                height: 265,
                width: '100%',
              }}
            >
              <CarImage
                layout='fill'
                alt={`${car.brand} ${car.model} ${car.year}`}
                onError={onImageError}
                src={`${apiPublicBaseUrl}/${car.images[0]}`}
              />
            </Box>
          )}
          {car.images?.length === 0 && (
            <CarImage
              src={fallbackUrl}
            />
          )}
        </CarThumb>
        <CarContent>
          <CarLabelsWrapper
            sx={{
              mt: 1
            }}
          >
            <CarLabel>
              <Icon alt="mileage" width={28} height={28} src='/static-web/images/icons/mileage.svg' />
              <LabelText>{car.mileage} {resolvedLanguage === 'ua' ? 'тис' : 'тыс'} км</LabelText>
            </CarLabel>
            <CarLabel justifyContent='end'>
              <Icon alt="fuel" width={28} height={28} src='/static-web/images/icons/fuel.svg' />
              <LabelText>{car.fuel}</LabelText>
            </CarLabel>
            <CarLabel>
              <Icon alt="engineVolume" width={28} height={28} src='/static-web/images/icons/engineVolume.svg' />
              {car.fuel === fuels[2].ua || car.fuel === fuels[2].ru ?
                <LabelText>{car.engineCapacity * 1000} кВт</LabelText> :
                <LabelText>{car.engineCapacity} л</LabelText>
              }
            </CarLabel>
            <CarLabel justifyContent='end'>
              <Icon alt="year" width={28} height={28} src='/static-web/images/icons/year.svg' />
              <LabelText>{car.year}</LabelText>
            </CarLabel>
            <CarLabel>
              <Icon alt="transmission" width={28} height={28} src='/static-web/images/icons/transmission.svg' />
              <LabelText>{car.transmission}</LabelText>
            </CarLabel>
            <CarLabel justifyContent='end'>
              <Icon alt="wheel-drive-type" width={28} height={28} src='/static-web/images/icons/WD.svg' />
              <LabelText>{car.wheelDriveType}</LabelText>
            </CarLabel>
          </CarLabelsWrapper>
          <CarPrice alignSelf='end'>${car.price}</CarPrice>
        </CarContent>
      </CarWrapper>
    // </Link>
  );
};

const LabelText = styled(Text)`
  min-width: 50px;
  font-size: 14px;
`

const Icon = styled(Image)`
  width: 27px !important;
  height: 27px !important;
  min-height: 27px !important;
  min-width: 27px !important;
  margin-right: 5px !important;
`

const CarLabel = styled(Flex).attrs({
  width: [1/2, 1/2, 1/2, 1/2],
  fontFamily: 'primary'
})`
  position: relative;
  padding: 5px 0;
  align-items: center;
  margin: 5px 0;
`

const CarLabelsWrapper = styled(Flex)`
  flex-wrap: wrap;
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.primary};
`

const CarTitle = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes[4]};
  padding: 5px 0;

  width: 100%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
`

const CarPrice = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes[4]};
  font-family: ${({ theme }) => theme.fonts.primaryBold};
  margin: 5px 0 5px;
`

const CarContent = styled(Flex)`
  padding: 10px 15px;
  flex-direction: column;
  justify-content: center;
`

const CarImage = styled(Image)`
  height: 265px;
  width: 100%;
  object-fit: cover;
  max-height: 265px;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 700px) {
    height: 200px;
  }
`

const CarThumb = styled(Flex)`
  position: relative;
  width: 100%;
  height: fit-content;
`

const CarWrapper = styled(Flex)`
  flex-direction: column;
  box-shadow: 0 12px 32px -6px rgb(21 34 66 / 10%), 0 0 1px rgb(21 34 66 / 22%);
  padding: 10px;
  min-height: 477px;
  flex-wrap: nowrap;
  max-width: 300px;
  min-width: 265px;
  margin: 0 auto;

  @media (max-width: 700px) {
    min-height: fit-content;
  }
  
  &:hover {
    box-shadow: 0 12px 32px -6px rgb(21 34 66 / 20%), 0 0 1px rgb(21 34 66 / 32%);
    cursor: pointer;
  }

  ${({ isSmMobile }) => isSmMobile && css`
    min-width: 235px;
  `}
`

CarItem.propTypes = {
  car: PropTypes.object
};

export default CarItem;
